import { useEffect, useState } from "react";
import { createContext } from "react";

export const SettingContext = createContext({});

function getSecureEmail() {
  const storedSecureEmail = localStorage.getItem("iReferSecureEmail");
  return storedSecureEmail !== null ? JSON.parse(storedSecureEmail) : true;
}

const SettingProvider = ({ children }) => {
  const [backdropOpen, setBackdropOpen] = useState(false);

  function getTheme() {
    const storedTheme = localStorage.getItem("iReferTheme");
    return storedTheme ? JSON.parse(storedTheme) : true;
  }

  const [secureEmail, setSecureEmail] = useState(() => getSecureEmail());

  function getDashboardChoice() {
    const storedChoice = localStorage.getItem("iReferPanel");
    return storedChoice ? JSON.parse(storedChoice) : false;
  }

  const [isAdminDashboard, setIsAdminDashboard] = useState(
    getDashboardChoice()
  );
  const [darkMode, setDarkMode] = useState(getTheme());

  useEffect(() => {
    const storedTheme = getTheme();
    setDarkMode(storedTheme);
  }, []);

  useEffect(() => {
    const storedChoice = getDashboardChoice();
    setIsAdminDashboard(storedChoice);
  }, []);

  useEffect(() => {
    localStorage.setItem("iReferSecureEmail", JSON.stringify(secureEmail));
  }, [secureEmail]);

  const values = {
    backdropOpen,
    setBackdropOpen,
    darkMode,
    setDarkMode,
    isAdminDashboard,
    setIsAdminDashboard,
    secureEmail,
    setSecureEmail,
  };

  return (
    <SettingContext.Provider value={values}>{children}</SettingContext.Provider>
  );
};

export default SettingProvider;
