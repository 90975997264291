import { useEffect, useState } from "react";
import { createContext } from "react";
import { getAllRoles } from "../services/adminroles";
import SuspenseFallback from "../components/SuspenseFallback";

export const DataContext = createContext({});

const DataContextProvider = ({ children }) => {
  const [referrals, setReferrals] = useState(null);
  const [roles, setRoles] = useState(null);

  const getAdminRoles = () => {
    getAllRoles().then((res) => {
      if (res && res.data) {
        setRoles(res.data.object);
      }
    });
  };
  useEffect(() => {
    getAdminRoles();
  }, []);

  const values = { referrals, setReferrals, roles, setRoles };

  if (roles) {
    return (
      <DataContext.Provider value={values}>{children}</DataContext.Provider>
    );
  }
  return <SuspenseFallback />;
};

export default DataContextProvider;
