import SendIcon from "@mui/icons-material/Send";
import React, { lazy, useContext } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import DraftsIcon from "@mui/icons-material/Drafts";
import { getCurrentUser } from "./services/auth";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ConstructionIcon from "@mui/icons-material/Construction";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { SettingContext } from "./context/settingContext";
import BadgeIcon from "@mui/icons-material/Badge";
import { DataContext } from "./context/dataContext";
import PendingConfig from "./pages/admin/pending/PendingConfig";
import PendingEdit from "./pages/admin/pending/pendingJobs/jobs/jobEdit/PendingEdit";
import PendingPromoEdit from "./pages/admin/pending/pendingPromo/PendingPromoEdit";
import PendingAdvocacyEdit from "./pages/admin/pending/pendingAdvocacy/PendingAdvocacyEdit";
import PendingMerchEdit from "./pages/admin/pending/pendingMerch/PendingMerchEdit";
import EmailIcon from "@mui/icons-material/Email";
import ResendOtpPage from "./components/ResendVerificationPage";
import ResendVerificationPage from "./components/ResendVerificationPage";

// Admin
const AdminDashboard = lazy(() =>
  import("./pages/admin/dashboard/AdminDashboard")
);
const UserConfig = lazy(() => import("./pages/admin/users/UserConfig"));

const ActivateDeactivateUser = lazy(() =>
  import("./pages/admin/users/activatedeactivate/ActivateDeactivateUser")
);

const Logz = lazy(() => import("./pages/admin/logs/Logs"));

const JobAdd = lazy(() => import("./pages/admin/landing/jobs/addJob/JobAdd"));

const EditJobPage = lazy(() =>
  import("./pages/admin/landing/jobs/editJob/EditJobPage")
);
const MerchAdd = lazy(() =>
  import("./pages/admin/general setting/merch/MerchAdd")
);
const MerchEdit = lazy(() =>
  import("./pages/admin/general setting/merch/MerchEdit")
);
const LandingConfig = lazy(() => import("./pages/admin/landing/LandingConfig"));
const GeneralSettings = lazy(() =>
  import("./pages/admin/general setting/GeneralSettings")
);
const ReferralAdd = lazy(() =>
  import("./pages/admin/general setting/referral/ReferralAdd")
);
const ReferralEdit = lazy(() =>
  import("./pages/admin/general setting/referral/ReferralEdit")
);
const ReferralStatus = lazy(() =>
  import("./pages/admin/referral/referralstatus/ReferralStatus")
);

const AdvocacyAdd = lazy(() =>
  import("./pages/admin/landing/advocacy/AdvocacyAdd")
);
const AdvocacyEdit = lazy(() =>
  import("./pages/admin/landing/advocacy/AdvocacyEdit")
);
const EditVideo = lazy(() => import("./pages/admin/landing/video/EditVideo"));

const ReferralConfig = lazy(() =>
  import("./pages/admin/referral/ReferralConfig")
);
const MerchOrderConfig = lazy(() =>
  import("./pages/admin/merch order/MerchOrderConfig")
);

const CategoryPointsEdit = lazy(() =>
  import("./pages/admin/general setting/category points/CategoryPointsEdit")
);
const StatusPointsEdit = lazy(() =>
  import("./pages/admin/general setting/status points/StatusPointsEdit")
);
const StatusPointsAdd = lazy(() =>
  import("./pages/admin/general setting/status points/StatusPointsAdd")
);
const PromoCarouselAdd = lazy(() =>
  import("./pages/admin/landing/promo carousel/PromoCarouselAdd")
);
const PromoCarouselEdit = lazy(() =>
  import("./pages/admin/landing/promo carousel/PromoCarouselEdit")
);
const MerchStatusPage = lazy(() =>
  import("./pages/admin/merch order/MerchStatusPage")
);

const EmailHistory = lazy(() =>
  import("./pages/admin/resend email/ResendEmail")
);

// User
const Merch = lazy(() => import("./pages/user/merchTab/MerchTab"));

const GeneralReferral = lazy(() =>
  import("./pages/user/generalreferral/GeneralReferral")
);
const UserJobs = lazy(() => import("./pages/user/jobs/UserJobs"));
const Incentives = lazy(() => import("./pages/user/incentives/Incentives"));
const Dashboard = lazy(() => import("./pages/user/dashboard/Dashboard"));
const ReferralUserConfig = lazy(() =>
  import("./pages/user/referrals/ReferralUserConfig")
);
const JobReferral = lazy(() =>
  import("./pages/user/generalreferral/job posting referral/JobReferral")
);
const JobMobile = lazy(() => import("./pages/user/jobs/JobMobile"));

// Public
const Profile = lazy(() => import("./pages/profile/Profile"));
const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const VerificationPage = lazy(() => import("./components/VerificationPage"));
const RegistrationVerificationPage = lazy(() =>
  import("./components/RegistrationVerificationPage")
);
const DashboardContent = lazy(() => import("./components/DashboardContent"));

// LOGIN AND REGISTRATION
const Login = lazy(() => import("./pages/login/Login"));
const Register = lazy(() => import("./pages/register/Register"));

// ADMIN ROLES
const Roles = lazy(() => import("./pages/admin/roles/Roles"));
const AddRoles = lazy(() => import("./pages/admin/roles/AddRoles"));
const EditRoles = lazy(() => import("./pages/admin/roles/EditRoles"));

// FORGOT PASSWORD
const ForgotPasswordPage = lazy(() =>
  import("./pages/forgotpassword/ForgotPasswordPage")
);

const EmailTemplateConfig = lazy(() =>
  import("./pages/admin/email template/EmailTemplateConfig")
);

export const adminRoutes = [
  {
    key: "Dashboard",
    element: <Navigate to="/irefer/dashboard" />,
    index: true,
  },
  // DASHBOARD
  {
    key: "Dashboard",
    icon: <DashboardIcon className="tabIcon" />,
    text: "Dashboard",
    path: "/irefer/dashboard",
    element: <AdminDashboard />,
  },

  // LANDING PAGE CONFIG
  {
    key: "Landing Page Config",
    icon: <SettingsSuggestIcon className="tabIcon" />,
    text: "Landing Page Config",
    path: "/irefer/landingconfig",
    element: <LandingConfig />,
  },

  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/job/add",
    element: <JobAdd />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/job/edit/:id",
    element: <EditJobPage />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/videolanding/edit/:id",
    element: <EditVideo />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/advocacy/add",
    element: <AdvocacyAdd />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/advocacy/edit/:id",
    element: <AdvocacyEdit />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/promocarousel/add",
    element: <PromoCarouselAdd />,
  },
  {
    key: "Landing Page Config",
    path: "/irefer/landingconfig/promocarousel/edit/:id",
    element: <PromoCarouselEdit />,
  },
  //GENERAL SETTINGS
  {
    key: "General Settings",
    icon: <ConstructionIcon className="tabIcon" />,
    text: "General Settings",
    path: "/irefer/generalsettings",
    element: <GeneralSettings />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/referral/add",
    element: <ReferralAdd />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/referral/edit/:id",
    element: <ReferralEdit />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/categorypoints/edit/:id",
    element: <CategoryPointsEdit />,
  },

  {
    key: "General Settings",
    path: "/irefer/generalsettings/statuspoints/add",
    element: <StatusPointsAdd />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/statuspoints/edit/:id",
    element: <StatusPointsEdit />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/merch/add",
    element: <MerchAdd />,
  },
  {
    key: "General Settings",
    path: "/irefer/generalsettings/merch/edit/:id",
    element: <MerchEdit />,
  },

  // EMAIL TEMPLATE
  {
    key: "Email Template",
    icon: <EmailIcon className="tabIcon" />,
    text: "Email Templates",
    path: "/irefer/emailtemplate",
    element: <EmailTemplateConfig />,
  },

  // MERCH ORDERS
  {
    key: "Merch Orders",
    icon: <LocalShippingIcon className="tabIcon" />,
    text: "Merch Orders",
    path: "/irefer/merchorder",
    element: <MerchOrderConfig />,
  },
  {
    key: "Merch Orders",
    path: "/irefer/merchorder/merch/user/:id",
    element: <MerchStatusPage />,
  },
  // PROFILE
  {
    key: "Profile",
    icon: <ManageAccountsIcon className="tabIcon" />,
    text: "Profile",
    path: "/irefer/profile",
    element: <Profile />,
  },
  //  USER
  {
    key: "User",
    icon: <AccountCircleIcon className="tabIcon" />,
    text: "User List",
    path: "/irefer/userList",
    element: <UserConfig />,
  },
  {
    key: "User",
    path: "/irefer/user/:id",
    element: <ActivateDeactivateUser />,
  },
  // REFERRAL
  {
    key: "Referral",
    icon: <SupervisedUserCircleIcon className="tabIcon" />,
    text: "Referrals",
    path: "/irefer/referral",
    element: <ReferralConfig />,
  },
  {
    key: "Referral",
    path: "/irefer/referral/:id",
    element: <ReferralStatus />,
  },

  // ROLES
  {
    key: "Admin Roles",
    icon: <BadgeIcon className="tabIcon" />,
    text: "Admin Roles",
    path: "/irefer/roles",
    element: <Roles />,
  },
  {
    key: "Admin Roles",
    path: "/irefer/roles/edit/:id",
    element: <EditRoles />,
  },
  { key: "Admin Roles", path: "/irefer/roles/add", element: <AddRoles /> },

  // PENDING TABS

  {
    key: "Pending Tabs",
    icon: <PendingActionsIcon className="tabIcon" />,
    text: "Pending Tabs",
    path: "/irefer/pendingconfig",
    element: <PendingConfig />,
  },

  {
    key: "Pending Tabs",
    path: "/irefer/pendingconfig/job/edit/:id",
    element: <PendingEdit />,
  },

  {
    key: "Pending Tabs",
    path: "/irefer/pendingconfig/promo/edit/:id",
    element: <PendingPromoEdit />,
  },

  {
    key: "Pending Tabs",
    path: "/irefer/pendingconfig/advocacy/edit/:id",
    element: <PendingAdvocacyEdit />,
  },

  {
    key: "Pending Tabs",
    path: "/irefer/pendingconfig/merch/info/:id",
    element: <PendingMerchEdit />,
  },

  // LOGS
  {
    key: "Logs",
    icon: <RestorePageIcon className="tabIcon" />,
    text: "Logs",
    path: "/irefer/allLogs",
    element: <Logz />,
  },

  //EmailHistory
  {
    key: "Failed Email Logs",
    icon: <DraftsIcon className="tabIcon" />,
    text: "Failed Email Logs",
    path: "/irefer/emailhistory",
    element: <EmailHistory />,
  },
  {
    path: "/irefer/emailhistory",
    element: <EmailHistory />,
  },
];

export const userRoutes = [
  { element: <Navigate to="/irefer/dashboard" />, index: true },

  {
    icon: <DashboardIcon className="tabIcon" />,
    text: "Dashboard",
    path: "/irefer/dashboard",
    element: <Dashboard />,
  },
  //PROFILE
  {
    icon: <ManageAccountsIcon className="tabIcon" />,
    text: "Profile",
    path: "/irefer/profile",
    element: <Profile />,
  },
  // REFERRALS
  {
    icon: <PeopleAltIcon className="tabIcon" />,
    text: "Referrals",
    path: "/irefer/referrals",
    element: <ReferralUserConfig />,
  },
  {
    icon: <SendIcon className="tabIcon" />,
    text: "General Referral",
    path: "/irefer/generalreferral",
    element: <GeneralReferral />,
  },
  //INCENTIVES
  {
    path: "/irefer/incentives",
    element: <Incentives />,
  },
  //JOBS
  {
    icon: <PersonSearchIcon className="tabIcon" />,
    text: "View Jobs",
    path: "/irefer/viewjobs",
    element: <UserJobs />,
  },
  {
    path: "/irefer/viewjobs/:id",
    element: <JobMobile />,
  },
  {
    path: "/irefer/referral/:id",
    element: <JobReferral />,
  },
  // MERCH
  {
    icon: <StorefrontIcon className="tabIcon" />,
    text: "Merch",
    path: "/irefer/merch",
    element: <Merch />,
  },
];

export default function Router() {
  const { isAdminDashboard } = useContext(SettingContext);
  const { roles } = useContext(DataContext);

  const getRoutes = () => {
    if (getCurrentUser()) {
      const user = getCurrentUser();
      const userRoles = roles.find((x) => x.role === user.role);
      if (user && user.role === "admin") {
        return isAdminDashboard ? adminRoutes : userRoutes;
      }
      if (userRoles) {
        if (isAdminDashboard) {
          const filteredAdminRoutes = adminRoutes.filter((route) =>
            userRoles.routes.includes(route.key)
          );
          return filteredAdminRoutes;
        }
        return userRoutes;
      } else return userRoutes;
    }
    return null;
  };

  const router = useRoutes([
    {
      path: "/",
      element: getCurrentUser() ? (
        <DashboardContent />
      ) : (
        <Navigate to="/irefer/landing" />
      ),
      children: getRoutes(),
    },
    {
      path: "/irefer/login",
      element: <Login />,
    },
    {
      path: "/irefer/verify/:token",
      element: <VerificationPage />,
    },
    {
      path: "/irefer/verification/status/:token",
      element: <RegistrationVerificationPage />,
    },
    {
      path: "/irefer/register",
      element: <Register />,
    },
    {
      path: "/irefer/landing",
      element: <LandingPage />,
    },
    {
      path: "/irefer/forgotpassword",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/irefer/resendVerificationPage",
      element: <ResendVerificationPage />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  return router;
}
