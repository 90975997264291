import React from "react";
import { Card, Button, Grid, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import SuspenseFallback from "../../../../../components/SuspenseFallback";

const PendingJobForm = ({ onSubmit, initialValue, onDelete }) => {
  const [jobForm, setJobForm] = useState(
    initialValue || {
      id: 0,
      jobTitle: "",
      jobDescription: "",
      category: "",
      jobPdf: "",
    }
  );

  const navigate = useNavigate();

  const jobDesc = jobForm?.jobDescription || "No description available";

  const formatDescription = (desc) => {
    let formattedDesc = desc.split('\n').join('<br />');
    formattedDesc = formattedDesc.replace(/(JOB DESCRIPTION|QUALIFICATIONS)/g, '<strong>$1</strong>');
    //formattedDesc = formattedDesc.replace(/- /gm, '<span style="display: inline-block; margin-left: 20px;">&#8226;&nbsp;</span>');
    formattedDesc = formattedDesc.replace(/(|\n)-\s*/g, '$1<span style="display: inline-block; margin-left: 20px;">&#8226;&nbsp;</span>');

    return formattedDesc;
  };
  const formattedDescription = formatDescription(jobDesc);

  if (jobForm) {
    return (
      <Card
        sx={{
          overflow: "auto",
          width: "100%",
          height: "85vh",
          boxShadow: 2,
          "&:hover": {
            boxShadow: 8,
          },
          mr: 1,
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            {jobForm.jobTitle} (
            {jobForm.category ? jobForm.category : "No category"})
          </Typography>
          <Typography
          gutterBottom
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
        />
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {jobForm.jobPdf ? (
              <iframe
                src={jobForm.jobPdf + "#toolbar=0"}
                title="PDF Viewer"
                width="100%"
                height="550px"
              />
            ) : (
              <p>No PDF available</p>
            )}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                fullWidth
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="error"
                onClick={() => onDelete(jobForm.id)}
                fullWidth
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="warning"
                variant="contained"
                fullWidth
                onClick={() => navigate("/irefer/pendingconfig")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return <SuspenseFallback />;
};

export default PendingJobForm;
